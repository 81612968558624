import '../style/Content.css';
import imageOne from '../asset/images/image1.png';
import imageTwo from '../asset/images/image2.png';
import imageThree from '../asset/images/image3.png';

function Content({children}){

    return (
        <div className='Content'>
            <div className='Content__images-box'>
                <div className='Content__image'>
                    <img className='Content__image-one' src={imageOne} alt='Artisan' />
                </div>

                <div className='Content__image'>
                    <img className='Content__image-two' src={imageTwo} alt='Artisan' />
                </div>

                <div className='Content__image'>
                    <img className='Content__image-three' src={imageThree} alt='Artisan' />
                </div>
            </div>
            {children}
        </div>
    )
}

export default Content;