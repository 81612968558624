import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import construction from './asset/en-construction.png';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <img className='under-construction-img' src={construction} alt='En construction' />
  </React.StrictMode>
);
