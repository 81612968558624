import '../style/Marquee.css';

export default function Marquee(){
    return(
        <div className='Marquee'>
            <div className='marquee-text .fadeout-horizontal'>
                <div className='marquee-text-track'>
                    <p>Architecte</p>
                    <p>Terrassier</p>
                    <p>Maçonnerie</p>
                    <p>Electricien</p>
                    <p>Plombier</p>
                    <p>Charpentier</p>
                    <p>Menuisier</p>
                    <p>Plaquiste</p>
                    <p>Carreleur</p>
                    <p>Peintre</p>
                    <p>Cuisiniste</p>
                    <p>Aménagement ext.</p>
                    <p>Rénovation</p>
                    <p>Maison individuelle</p>
                    
                    <p aria-hidden="true">Architecte</p>
                    <p aria-hidden="true">Terrassier</p>
                    <p aria-hidden="true">Maçonnerie</p>
                    <p aria-hidden="true">Electricien</p>
                    <p aria-hidden="true">Plombier</p>
                    <p aria-hidden="true">Charpentier</p>
                    <p aria-hidden="true">Menuisier</p>
                    <p aria-hidden="true">Plaquiste</p>
                    <p aria-hidden="true">Carreleur</p>
                    <p aria-hidden="true">Peintre</p>
                    <p aria-hidden="true">Cuisiniste</p>
                    <p aria-hidden="true">Aménagement ext.</p>
                    <p aria-hidden="true">Rénovation</p>
                    <p aria-hidden="true">Maison individuelle</p>
                </div>
            </div>
        </div>
    )
}