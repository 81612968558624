import './App.css';
import Header from './component/Header';
import Content from './component/Content';
import Marquee from './component/Marquee';
import { motion } from "framer-motion";

function App() {

  return (
    <div className="App">
      <Header />
      <Content>
        <p className='message-accueil'>
          Rechercher (<motion.span 
                      initial={{opacity: 0.05}} 
                      animate={{opacity: 0.9}} 
                      transition={{repeat: Infinity, duration: 2}}> 
            bientôt </motion.span>) 
          des <strong>artisans de qualité </strong> 
          à <strong>proximité</strong> de <span className='underlined'>chez vous</span>.
        </p>
      </Content>
      <p className='message-accueil-mobile'>
          Rechercher (<motion.span 
                      initial={{opacity: 0}} 
                      animate={{opacity: 1}} 
                      transition={{repeat: Infinity, duration: 2, ease: [0.65,0.05,0.36,1]}}> 
            bientôt </motion.span>) 
          des <strong>artisans de qualité </strong> 
          à <strong>proximité</strong> de <span className='underlined'>chez vous</span>.
        </p>
        <Marquee />
    </div>
  );
}

export default App;
