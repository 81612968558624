import '../style/Header.css';
import logo from '../asset/KALIPRO.png';

function Header(){
    return (
        <div className='Header'>
            <img className='Header__logo' src={logo} alt='LOGO' /> 
            {/* <div className='Header__construction'>Site en construction</div> */}
        </div>
    )
}

export default Header;